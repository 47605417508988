import { gql } from "@apollo/client"
export const get_all_vendor_list = gql`
  query getAllVendors(
    $search_string: String
    $page_number: Float
    $page_limit: Float
  ) {
    get_all_vendors: getAllVendors(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        contact_person_name
        description
        logo
        contact_number
        facebook_url
        instagram_url
        twitter_url
        owner {
          email
          mobile
        }
        vendor_address {
          address
          door_no
          id
          mobile_no
          address_line1
          address_line2
          city
          zipcode
          state {
            name
          }
          country {
            name
          }
          district {
            name
          }
        }
        vendor_products {
          id
        }
      }
      pagination {
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_vendor_products = gql`
  query getVendor($id: String!) {
    get_vendor: getVendor(id: $id) {
      id
      name
      contact_person_name
      description
      logo
      owner_id
      contact_number
      website
      ceo_name
      ceo_contact_number
      agency_name
      name_of_commodities
      owner {
        email
        mobile
      }
      vendor_address {
        address
        door_no
        mobile_no
        address_line1
        address_line2
        city
        contact_person
        mobile_no
        state {
          name
        }
        country {
          name
        }
        district {
          name
        }
        zipcode
      }
      facebook_url
      instagram_url
      twitter_url
      location
      vendor_products {
        product {
          id
          name
          category {
            id
            name
          }
        }
        vendor {
          id
          name
        }
        vendor_id
        product_id
        vendor_product_variants {
          id
          name
          description
          icon
          product_tax_id
          stock_keeping_unit
          base_price
          stock_count
          value
          original_price
          selling_price
          discount
          product_tax_id
          unit
          product_rating {
            rating
          }
        }
      }
    }
  }
`;