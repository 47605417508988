import { navigate } from "@helpers/navigator";
import ForgotComponent from "@views/components/auth/forgot_password_form";
import { ROUTES } from "@views/routes/my_routes";
import { Box, Button, Pressable, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const Home = (props) => {
  const dispatch = useDispatch();
  return (
    <Box alignItems={"center"} flex={1}>
      <Button
        width={"100px"}
        mt="5"
        size="md"
        borderRadius="4"
        _text={{
          fontWeight: "medium",
        }}
        _light={{
          bg: "primary.900",
        }}
        _dark={{
          bg: "primary.700",
        }}
        onPress={() => {
          navigate(ROUTES.LOGIN);
        }}
      >
        Navigate
      </Button>
      <ForgotComponent />
    </Box>
  );
};
export default Home;
